exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-index-js": () => import("./../../../src/pages/apply/index.js" /* webpackChunkName: "component---src-pages-apply-index-js" */),
  "component---src-pages-apply-query-js": () => import("./../../../src/pages/apply/Query.js" /* webpackChunkName: "component---src-pages-apply-query-js" */),
  "component---src-pages-developer-index-js": () => import("./../../../src/pages/developer/index.js" /* webpackChunkName: "component---src-pages-developer-index-js" */),
  "component---src-pages-hourofcode-index-js": () => import("./../../../src/pages/hourofcode/index.js" /* webpackChunkName: "component---src-pages-hourofcode-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-katanga-js": () => import("./../../../src/pages/katanga.js" /* webpackChunkName: "component---src-pages-katanga-js" */)
}

